import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StarRatings from 'react-star-ratings';

class Rating extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="mb-2">
        <StarRatings
          rating={Number(this.props.rating)}
          numberOfStars={5}
          starRatedColor={'rgb(5, 59, 246)'}
          starDimension="20px"
          starSpacing="1px"
          className="mb-2"
        />
      </div>
    );
  }
}

Rating.propTypes = {
  rating: PropTypes.Number
}

export default Rating