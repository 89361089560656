import React from 'react'
import PropTypes from "prop-types"

const Affiliates = ({ affiliates }) => {
  return (
    <div className="container mx-auto">
      <h3>Where to Purchase</h3>
      {
        affiliates.map(affiliate => {
          return (
            <div key={affiliate.field_affiliate_id}>
              <span className="mr-2">{affiliate.relationships.field_affiliate_name.name}</span>
              <span>{affiliate.field_affiliate_id}</span>
            </div>
          )
        })
      }
    </div>
  )
}

Affiliates.propTypes = {
  affiliates: PropTypes.object
};

export default Affiliates
