import React from "react"
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby"
import Layout from '../components/Layout/Layout'
import Affiliates from '../components/Layout/Affiliates'
import Rating from '../components/Layout/Rating'
import Img from 'gatsby-image'


const Template = ({ data, location }) => {
  const product = data.nodeProduct
  let videoLink = null
  if (product?.field_youtube?.video_id) {
    videoLink = 'https://www.youtube.com/embed/' + product.field_youtube.video_id
    videoLink = null
  }
  return (
    <Layout location={location} nid={product.drupal_internal__nid}>
      <section className="container mx-auto px-8">
        <div className="page-title">{product.title}</div>
        {product.field_rating &&
          <Rating rating={product.field_rating} />
        }
        {product.relationships.field_brand &&
          <Link to={product.relationships.field_brand.path.alias} className="text-lg block font-semibold">
              {product.relationships.field_brand.name}
          </Link>
        }
        { product.relationships.field_image &&
          <div className="float-right ml-3 mb-2 w-full md:w-1/3">
            <Img fluid={
              product.relationships.field_image.localFile.childImageSharp.fluid
            } />
          </div>
        }
        {videoLink &&
          <div
            style={{
              paddingBottom: '56.25%',
              paddingTop: '30px',
            }}
            className="h-0 overflow-hidden relative"
          >
            <iframe src={videoLink}
              frameBorder="0"
              allow="encrypted-media"
              allowFullScreen
              title="video"
              className="absolute w-full h-full t-0 l-0"
            />
        </div>
        }
        <div
          className="mt-10"
          dangerouslySetInnerHTML={{ __html: product.field_description.value }}>
        </div>
        {product.relationships?.field_affiliate_link[0] && videoLink &&
          <Affiliates affiliates={product.relationships.field_affiliate_link} />
        }
      </section>
    </Layout>
  )
}

Template.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default Template;

export const query = graphql`
  query($productId: String!) {
    nodeProduct(id: { eq: $productId }) {
      id
      title
      field_description {
        value
      }
      drupal_internal__nid
      field_rating
      path {
        alias
      }
      field_youtube {
        video_id
      }
      relationships {
        field_brand {
          name
          path {
            alias
          }
        }
        field_affiliate_link {
          field_affiliate_id
          relationships {
            field_affiliate_name {
              name
            }
          }
        }
        field_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 650, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }`